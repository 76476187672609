import React, { useEffect, useRef, useState } from 'react';
import styles from './NumberQuiz.module.scss';
import { Form } from 'antd';
import {
  checkAnswers,
  getRandomArbitrary,
  getTitle,
  randomNumber,
  setDuration,
  signContent,
  updateQuizPayload,
  getCurrentRoute,
  getRandomValues,
  generateAddNumbers,
  generateSubNumbers,
  generateNumbersDivisible,
} from '../../../helpers/utility';
import ResultModal from '../ResultModal/ResultModal';
import { QuestionStartClick } from '../../../helpers/QuestionTimer';
import QuizHeader from '../QuizLayout/QuizHeader/QuizHeader';
import QuizFooterHandleTimer from '../QuizLayout/QuizFooter/QuizFooterHandleTimer';
import { attemptSetQuiz, getActivitiesListAction } from '../../../store/actions/quizActions';
import { useDispatch } from 'react-redux';
import { useReducerData } from '../../../store/hooks';
import CustomInputNumber from '../../../components/InputNumberBox/inputNumber';
import { useLocation, useNavigate } from 'react-router-dom';

const NumberQuiz = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const dispatch = useDispatch();
  const questionRef = useRef(null);
  const data = useReducerData('quiz', 'quizData');
  const tabKey = useReducerData('quiz', 'tabKey');
  const currentQuizKey = useReducerData('quiz', 'currentQuizKey');
  const { key, subKey } = currentQuizKey[+tabKey];
  const elements = data?.data?.[+tabKey]?.quiz;
  const updateQuizData = currentQuizKey[+tabKey];

  const [isDisplayNext, setIsDisplayNext] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [resultModalVisible, setResultModalVisible] = useState(false);
  const [randomNumberText, setRandomNumberText] = useState(
    Number(tabKey) === 1
      ? generateSubNumbers(key)
      : Number(tabKey) === 3
      ? generateNumbersDivisible(key)
      : generateAddNumbers(Number(tabKey) === 2 ? 25 : 41, key),
  );
  const [form] = Form.useForm();
  const [quizData, setQuizData] = useState(elements);
  const [questionTimer, setQuestionTimer] = useState(key > 6 ? '18:00' : '15:00');
  const [ansValues, setAnsValues] = useState(0);
  const [isFinishQuestions, setIsFinishQuestions] = useState(false);
  const url = window.location.href;
  const currentData = quizData[key]?.data;
  const getUnitName = data?.data?.[+tabKey].unitName;
  const [quizQuestionKey, setQuizQuestionKey] = useState(getCurrentRoute(url, currentData));
  const [indexNumber, setIndexNumber] = useState(0);
  let totalTime = key > 6 ? 18 : 15;

  const handleSetQuizData = (prev, activeAt) => {
    const updateQuizData = [...prev];
    updateQuizData[activeAt].data[subKey].isActive = false;
    updateQuizData[activeAt].data[subKey].duration = key > 6 ? '18:00' : '15:00';
    if (!(activeAt === quizData.length - 1)) updateQuizData[activeAt + 1].data[subKey].isActive = true;
    return updateQuizData;
  };

  useEffect(() => {
    if (questionTimer === '00:00') {
      const activeAt = quizQuestionKey;
      setQuizData((prev) => {
        return handleSetQuizData(prev, activeAt);
      });
      handleChangeQuestionKey();
    }
    let quizDataTemp = [...data.data];
    quizDataTemp[tabKey].quiz[key].data[quizQuestionKey].correctAnswer = 0;
    quizDataTemp[tabKey].quiz[key].data[quizQuestionKey].wrongAnswer = 0;

    dispatch(
      attemptSetQuiz({
        data: quizDataTemp,
        activity: false,
      }),
    );
  }, [questionTimer]);

  useEffect(() => {
    if (state?.nextKey) {
      sessionStorage.removeItem('hasReloaded');
    }
  });

  const handleChangeQuestionKey = () => {
    QuestionStartClick(questionTimer, setQuestionTimer, key > 6 ? '18:00' : '15:00');
    setQuizQuestionKey((prev) => prev);
  };

  const handleKeyDown = async (e) => {
    if (!isFinishQuestions) {
      if (e.key === 'ArrowDown' || e.key === 'ArrowUp' || e.key === '+') {
        e.preventDefault();
        e.stopPropagation();
        return;
      }

      if (e.key === 'Enter' && currentQuestion <= randomNumberText?.length) {
        if (e.target.value === '') {
          return;
        }
        if (currentQuestion !== randomNumberText?.length) {
          setIndexNumber(indexNumber + 1);
        }
        setCurrentQuestion(currentQuestion + 1);
        // generateValue(indexNumber + 1);
        const count = currentQuestion + 1;
        // if (count === currentValue) {
        //   setCurrentValue(
        //     currentValue + (Number(tabKey) === 0 ? 22 : Number(tabKey) === 1 ? 20 : Number(tabKey) === 3 ? 13 : 26),
        //   );
        //   setCurrentActivity(currentActivity === 0 ? currentActivity : currentActivity - 1);
        // }
        let quizDataTemp = [...quizData];
        let checkValue = checkAnswers(
          String(tabKey),
          Number(Object.keys(getQuizValues(indexNumber))[0]),
          Number(Object.values(getQuizValues(indexNumber))[0]),
          +e.target.value,
        );

        if (checkValue) {
          quizDataTemp[key].data[quizQuestionKey].correctAnswer =
            quizDataTemp[key].data[quizQuestionKey].correctAnswer + 1;
        } else {
          quizDataTemp[key].data[quizQuestionKey].wrongAnswer = quizDataTemp[key].data[quizQuestionKey].wrongAnswer + 1;
        }

        if (currentQuestion !== randomNumberText?.length) {
          do {
            const nextNumber = getRandomValues(Number(tabKey), 0, key);
            if (nextNumber !== randomNumberText) {
              // setRandomNumberText(nextNumber);
              break;
            }
          } while (true);
        }

        if (currentQuestion === randomNumberText?.length) {
          setQuizData((prev) => {
            const currentQuestionKey = quizQuestionKey;
            const updateQuizDataTemp = quizDataTemp;
            updateQuizDataTemp[key].data[currentQuestionKey].duration = setDuration(questionRef.current, totalTime);
            updateQuizDataTemp[key].data[currentQuestionKey].isActive = false;
            if (!(key === quizData.length - 1)) updateQuizDataTemp[key].data[1].isActive = true;
            if (subKey === elements[key].data.length - 1) {
              setQuizQuestionKey(quizQuestionKey + 1);
            }
            return updateQuizDataTemp;
          });
        } else {
          setQuizData(quizDataTemp);
        }
        if (currentQuestion !== randomNumberText?.length) {
          form.resetFields();
        }
      }
      if (e.key === 'Enter' && currentQuestion === randomNumberText?.length) {
        if (e.target.value === '') {
          return;
        }
        setCurrentQuestion(currentQuestion + 1);

        const wrongAnswer = quizData[key].data[quizQuestionKey].wrongAnswer;
        const correctAnswer = quizData[key].data[quizQuestionKey].correctAnswer;
        const tempQuizData = updateQuizPayload(
          data.data,
          +tabKey,
          key,
          getCurrentRoute(url, currentData),
          setDuration(questionRef.current, totalTime),
          wrongAnswer,
          correctAnswer,
          true,
          randomNumberText?.length,
          updateQuizData,
        );
        setIsFinishQuestions(true);
        await dispatch(
          attemptSetQuiz({
            data: tempQuizData?.tempQuizData,
            activity: tempQuizData?.activity,
          }),
        );
        dispatch(getActivitiesListAction(getUnitName));
        setResultModalVisible(true);
      }
    }
  };

  const onTimeOut = async () => {
    setCurrentQuestion(currentQuestion + 1);

    const wrongAnswer = quizData[key].data[quizQuestionKey].wrongAnswer;
    const correctAnswer = quizData[key].data[quizQuestionKey].correctAnswer;
    const tempQuizData = updateQuizPayload(
      data.data,
      +tabKey,
      key,
      getCurrentRoute(url, currentData),
      setDuration(questionRef.current, totalTime),
      wrongAnswer,
      correctAnswer,
      true,
      randomNumberText?.length,
      updateQuizData,
    );
    setIsFinishQuestions(true);
    await dispatch(
      attemptSetQuiz({
        data: tempQuizData?.tempQuizData,
        activity: tempQuizData?.activity,
      }),
    );

    setResultModalVisible(true);
  };
  const getQuizValues = (index) => {
    return randomNumberText[index];
  };

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.modalContainer}>
          <QuizHeader isDisplayNext={false} title={'Quiz'} />
          <div className={styles.subHeading}>
            <span>{getTitle(+tabKey, key)}</span>
          </div>
          <div className={styles.questionContainer}>
            <div className={styles.detailContainer}>
              <div className={styles.number1}>
                {getQuizValues(indexNumber) ? Object.keys(getQuizValues(indexNumber))[0] : 0}
              </div>
              <div className={styles.number2}>
                <span className={styles.signContainer}>{signContent(String(tabKey))}</span>
                <span>{getQuizValues(indexNumber) ? Object.values(getQuizValues(indexNumber))[0] : 0}</span>
              </div>

              <div className={styles.answer}>
                <hr />
                <Form form={form} colon={false} autoComplete="off">
                  <CustomInputNumber
                    inputType="answer"
                    name="answer"
                    onKeyDown={handleKeyDown}
                    isDisables={!isDisplayNext || isFinishQuestions}
                    value={ansValues}
                    onChange={(e) => {
                      setAnsValues(e);
                    }}
                  />
                </Form>
              </div>
            </div>
          </div>

          <QuizFooterHandleTimer
            isDisplayNext={isDisplayNext}
            setIsDisplayNext={setIsDisplayNext}
            isDisabled={true}
            questionRef={questionRef}
            totalTime={key > 6 ? 18 : 15}
            onTimeOut={onTimeOut}
          />
        </div>
      </div>
      {resultModalVisible && (
        <ResultModal
          resultModalVisible={resultModalVisible}
          setResultModalVisible={setResultModalVisible}
          isNextQuiz={true}
          isNextActivity={quizQuestionKey}
        />
      )}
    </>
  );
};

export default NumberQuiz;
